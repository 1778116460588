import React from 'react';
import classes from './SkeletonBeerCard.module.scss';
import Skeleton from '../Skeleton';

export default function SkeletonBeerCard({ cardType }) {
  return (
    <div className={cardType === 2 ? classes.beerCardTwo : classes.beerCardThree}>
      <div className={classes.card}>
        <div className={classes.imgDiv}>
          <div className={classes.img}>
            <Skeleton borderRadius='0' width='100%' height='100%' isLight />
          </div>
        </div>

        <div className={classes.description}>
          <div className={classes.text}>
            <div className={classes.title}>
              <Skeleton borderRadius='5px' width='100%' height='100%' isLight={false} />
            </div>
            <div className={classes.type}>
              <Skeleton borderRadius='5px' width='100%' height={15} isLight={false} />
            </div>
          </div>
          <div className={classes.prices}>
            <div className={classes.price}>
              <Skeleton borderRadius='5px' width='100%' height='100%' isLight={false} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
