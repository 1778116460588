import React from 'react';
import classes from './Skeleton.module.scss';

export default function Skeleton({ height, width, borderRadius, isLight }) {
  const styles = {
    width,
    height,
    borderRadius,
  };
  return <div className={`${classes.skeleton} ${isLight ? classes.light : classes.dark}`} style={styles} />;
}
