import React from 'react';
import classes from './SkeletonBeer.module.scss';
import classesBeer from '../../../pages/Beer/BeerSearch/BeerSearch.module.scss';
import Skeleton from '../Skeleton';
import { GeneralContext } from '../../../context/generalContext';
import SkeletonBeerCard from '../SkeletonBeerCard/SkeletonBeerCard';

export default function SkeletonBeer() {
  const { subdomainIsActive, windowWidth } = React.useContext(GeneralContext);

  const section1Items = [1, 2, 3];
  const section2Items = [1, 2];
  const otherSections = [1, 2, 3, 4];
  const filterItems = [1, 2, 3];
  const beerItems = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  const mobTypes = [1, 2, 3];

  return (
    <>
      {windowWidth > 767 && (
        <>
          <div className={classes.navPanel}>
            <Skeleton borderRadius='5px' width={250} height={15} isLight={false} />
          </div>
          <div className={classesBeer.beerSearch}>
            <div className={classesBeer.beerSearchLeft}>
              {!subdomainIsActive && (
                <div>
                  <div className={classes.header1}>
                    <Skeleton borderRadius='5px' width='100%' height={20} isLight={false} />
                  </div>
                  <div className={classes.dropdown}>
                    <Skeleton borderRadius='5px' width='100%' height={15} isLight={false} />
                  </div>
                </div>
              )}
              <div className={classes.sections}>
                {section1Items.map((item, index) => (
                  <div className={classes.sectionsItem} key={index}>
                    <div className={classes.sectionRound}>
                      <Skeleton borderRadius='50%' width={24} height={24} isLight={false} />
                    </div>
                    <div className={classes.sectionLine}>
                      <Skeleton borderRadius='5px' width='100%' height={15} isLight={false} />
                    </div>
                  </div>
                ))}
              </div>
              <div className={classes.sections}>
                <div className={classes.sectionHeader}>
                  <Skeleton borderRadius='5px' width='100%' height={20} isLight={false} />
                </div>
                {section2Items.map((item, index) => (
                  <div className={classes.sectionsItem} key={index}>
                    <div className={classes.sectionRound}>
                      <Skeleton borderRadius='2px' width={24} height={24} isLight={false} />
                    </div>
                    <div className={classes.sectionLine}>
                      <Skeleton borderRadius='5px' width='100%' height={15} isLight={false} />
                    </div>
                  </div>
                ))}
              </div>
              {otherSections.map((section, index) => (
                <div className={classes.sections} key={index}>
                  <div className={classes.sectionHeader2}>
                    <Skeleton borderRadius='5px' width='100%' height={20} isLight={false} />
                  </div>
                </div>
              ))}
            </div>
            <div className={classesBeer.beerSearchRight}>
              <div className={classes.searchDiv}>
                <div className={classes.search}>
                  <Skeleton borderRadius='5px' width={140} height={15} isLight={false} />
                </div>
                <div className={classes.sort}>
                  <Skeleton borderRadius='5px' width='100%' height={15} isLight={false} />
                </div>
              </div>
              <div className={classes.header2}>
                <Skeleton borderRadius='5px' width={140} height={25} isLight={false} />
              </div>
              <div className={classes.filters}>
                {filterItems.map((el, index) => (
                  <div className={classes.filter} key={index}>
                    <Skeleton borderRadius='5px' width='100%' height={15} isLight={false} />
                  </div>
                ))}
              </div>
              <div className={classes.beerItems}>
                {beerItems.map((el, index) => (
                  <SkeletonBeerCard key={index} cardType={2} />
                ))}
              </div>
            </div>
          </div>
        </>
      )}
      {windowWidth <= 767 && (
        <>
          <div className={classes.mobHeader}>
            <Skeleton borderRadius='5px' width={200} height={20} isLight={false} />
          </div>
          <div className={classes.mobTypes}>
            {mobTypes.map((el, index) => (
              <Skeleton borderRadius='5px' width='29%' height={15} isLight={false} key={index} />
            ))}
          </div>
          <div className={classes.mobFilter}>
            <Skeleton borderRadius='5px' width={160} height={15} isLight={false} />
          </div>
          <div className={classes.mobFilter2}>
            <Skeleton borderRadius='5px' width={160} height={15} isLight={false} />
          </div>
          <div className={classes.mobParams}>
            {!subdomainIsActive && (
              <div className={classes.mobParam1}>
                <Skeleton borderRadius='5px' width={120} height={15} isLight={false} />
              </div>
            )}
            <div className={classes.mobParam2} />
            <div className={classes.mobParam2} />
          </div>
          <div className={classes.beerItems}>
            {beerItems.map((el, index) => (
              <SkeletonBeerCard key={index} cardType={2} />
            ))}
          </div>
        </>
      )}
    </>
  );
}
