import { createContext, useState } from 'react';

export const BeerContext = createContext();

function BeerContextProvider({ children }) {
  const [sections, setSections] = useState([]);

  const [activeSection, setActiveSection] = useState('');
  const popularSections = sections.filter(section => section.popular === 1);

  // крепость
  const [strength, setStrength] = useState([]);
  const [activeStrength, setActiveStrength] = useState('');

  const [countryFlags, setCountryFlags] = useState([
    { id: 1, name: 'Бельгия', name_en: 'Belgium', img: 'beer-flag-be.jpg' },
    { id: 2, name: 'Бельгия,Валлония', name_en: 'Belgium', img: 'beer-flag-be.jpg' },
    { id: 3, name: 'Великобритания', name_en: 'Great Britain', img: 'beer-flag-gb.jpg' },
    { id: 4, name: 'Англия', name_en: 'England', img: 'beer-flag-england.jpg' },
    { id: 5, name: 'Шотландия', name_en: 'Scotland', img: 'beer-flag-scotland.jpg' },
    { id: 6, name: 'Нидерланды', name_en: 'Netherlands', img: 'beer-flag-neth.jpg' },
    { id: 7, name: 'Голландия', name_en: 'Netherlands', img: 'beer-flag-neth.jpg' },
    { id: 8, name: 'Швеция', name_en: 'Sweden', img: 'beer-flag-sweden.jpg' },
    { id: 9, name: 'Германия', name_en: 'Germany', img: 'beer-flag-de.jpg' },
    { id: 10, name: 'Дания', name_en: 'Denmark', img: 'beer-flag-denmark.jpg' },
    { id: 11, name: 'Россия', name_en: 'Russia', img: 'beer-flag-rus.jpg' },
    { id: 12, name: 'США', name_en: 'USA', img: 'beer-flag-usa.jpg' },
    { id: 13, name: 'Ирландия', name_en: 'Ireland', img: 'beer-flag-ireland.jpg' },
    { id: 14, name: 'Испания', name_en: 'Spain', img: 'beer-flag-spain.jpg' },
    { id: 15, name: 'Франция', name_en: 'France', img: 'beer-flag-fr.jpg' },
    { id: 16, name: 'Италия', name_en: 'Italy', img: 'beer-flag-it.jpg' },
    { id: 17, name: 'Австрия', name_en: 'Austria', img: 'beer-flag-austria.jpg' },
  ]);

  const [countries, setCountries] = useState([]);
  const [activeCountry, setActiveCountry] = useState('');

  const [breweries, setBreweries] = useState([]);
  const [activeBrewery, setActiveBrewery] = useState('');

  const [colors, setColors] = useState([]);
  const [activeColor, setActiveColor] = useState('');

  const [beer, setBeer] = useState([]);

  // для типа емкости - разливное (все позиции из секции разливного - id 242) и бутылочное (все остальное )
  const [bottles] = useState([
    { id: 1, title: '\u0420\u0430\u0437\u043b\u0438\u0432\u043d\u043e\u0435', title_en: 'Draft', slug: 'search' },
    {
      id: 2,
      title: '\u0411\u0443\u0442\u044b\u043b\u043e\u0447\u043d\u043e\u0435',
      title_en: 'Bottled',
      slug: 'search',
    },
  ]);

  const [activeBottle, setActiveBottle] = useState('');

  // для поиска
  const [filter, setFilter] = useState('');

  return (
    <BeerContext.Provider
      value={{
        sections,
        setSections,
        activeSection,
        setActiveSection,
        popularSections,
        strength,
        setStrength,
        activeStrength,
        setActiveStrength,
        countries,
        setCountries,
        activeCountry,
        setActiveCountry,
        breweries,
        setBreweries,
        activeBrewery,
        setActiveBrewery,
        colors,
        setColors,
        activeColor,
        setActiveColor,
        bottles,
        activeBottle,
        setActiveBottle,
        filter,
        setFilter,
        countryFlags,
        setCountryFlags,
        beer,
        setBeer,
      }}
    >
      {children}
    </BeerContext.Provider>
  );
}

export { BeerContextProvider };
