import React, { createContext, useState } from 'react';

export const AboutContext = createContext();

function AboutContextProvider({ children }) {
  const [activeSection, setActiveSection] = useState(1);

  return (
    <AboutContext.Provider
      value={{
        activeSection,
        setActiveSection,
      }}
    >
      {children}
    </AboutContext.Provider>
  );
}

export { AboutContextProvider };
