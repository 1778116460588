export const getBaseUrl = () => {
  let originalUrl = window.location.origin;

  if (window.location.hostname === 'yandexwebcache.net') {
    const urlParams = new URLSearchParams(window.location.search);
    const urlFromParams = urlParams.get('url') || window.location.origin;

    const parsedUrl = new URL(urlFromParams);
    originalUrl = parsedUrl.origin;
  }

  return originalUrl;
};
