import { createContext, useState } from 'react';

export const GeneralContext = createContext();

function GeneralContextProvider({ children }) {
  const [menuItems, setMenuItems] = useState([
    { id: 1, name: 'Меню', href: '/menu', name_en: 'Menu' },
    { id: 2, name: 'Пиво', nameTashkent: 'Напитки', href: '/beer', name_en: 'Beer', nameTashkent_en: 'Bar' },
    { id: 3, name: 'Резерв стола', href: '/order', name_en: 'Reservations' },
    { id: 4, name: 'Адреса', href: '/address', name_en: 'Addresses' },
    { id: 5, name: 'События', href: '/events', name_en: 'Events' },
    { id: 6, name: 'О нас', href: '/about/info', name_en: 'About' },
  ]);

  const [activeMenuItem, setActiveMenuItem] = useState();

  const [cities, setCities] = useState([
    {
      id: 1,
      name: 'Москва',
      name_en: 'Moscow',
      footer_name: 'Москва',
      footer_name_en: 'Moscow',
      link: 'lambic.ru',
      testLink: 'lambic.keep-calm.ru',
      stageLink: 'stage.lambic.ru',
      email: 'info@lambic.ru',
    },
    {
      id: 2,
      name: 'Новосибирск',
      name_en: 'Novosibirsk',
      footer_name: 'Новосибирск',
      footer_name_en: 'Novosibirsk',
      link: 'nsk.lambic.ru',
      testLink: 'nsk.lambic.keep-calm.ru',
      stageLink: 'nsk.stage.lambic.ru',
      email: 'nsk@lambic.ru',
    },
    {
      id: 4,
      name: 'Воронеж',
      name_en: 'Voronezh',
      footer_name: 'Воронеж',
      footer_name_en: 'Voronezh',
      link: 'vrn.lambic.ru',
      testLink: 'vrn.lambic.keep-calm.ru',
      stageLink: 'vrn.stage.lambic.ru',
      email: 'brasseri.vrn@mail.ru',
    },
    {
      id: 5,
      name: 'Новокузнецк',
      name_en: 'Novokuznetsk',
      footer_name: 'Новокузнецк',
      footer_name_en: 'Novokuznetsk',
      link: 'nkz.lambic.ru',
      testLink: 'nkz.lambic.keep-calm.ru',
      stageLink: 'nkz.stage.lambic.ru',
      email: 'nkz@lambic.ru',
    },
    {
      id: 6,
      name: 'Ташкент',
      name_en: 'Tashkent',
      footer_name: 'Ташкент',
      footer_name_en: 'Tashkent',
      link: 'lambic.uz',
      testLink: 'tashkent.lambic.keep-calm.ru',
      stageLink: 'tashkent.stage.lambic.ru',
      email: 'info@lambic.uz',
    },
  ]);

  const [activeCity, setActiveCity] = useState(0);

  const [languages, setLanguages] = useState([
    { id: 1, name: 'Russian' },
    { id: 2, name: 'English' },
  ]);
  const [activeLanguage, setActiveLanguage] = useState(1);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth); // ширина экрана
  const [windowHeight, setWindowHeight] = useState(window.innerHeight); // ширина экрана

  // для определения ширины экрана
  const detectWidth = () => {
    setWindowWidth(window.innerWidth);
  };

  const [path, setPath] = useState(window.location.pathname);
  const [subdomain, setSubdomain] = useState(window.location.hostname.split('.')[0]);
  const [subdomainIsActive, setSubdomainIsActive] = useState(false);

  const addressesForTitle = {
    11: 'на Курской',
    12: 'на Мясницкой',
    20: 'на Новослободской',
    21: 'на Неверовского',
    614: 'на Проспекте Мира',
    1208: 'на Большой Ордынке',
    3045: 'на Страстном бульваре',
    3346: 'на Таганской',
    3344: 'на Арбатской',
    3348: 'на Китай-городе',
    39680: 'на Парке Культуры',
    40523: 'на Академической',
    40653: 'в Москва-Сити',
    41379: 'на Ходынском бульваре',
    43455: 'на Краснопролетарской',
    44034: 'на Шаболовке',
    44038: 'на Красина',
    44621: 'Ташкент',
    41298: 'на Тимирязева',
    44617: 'на Никитской',
    44623: 'на Ермакова',
  };

  const addressesForTitle_en = {
    11: 'at Kurskaya',
    12: 'on Myasnitskaya',
    20: 'on Novoslobodskaya',
    21: 'on Neverovskogo',
    614: 'on Prospect Mira',
    1208: 'on Bolshaya Ordynka',
    3045: 'on Strastnoy Boulevard',
    3346: 'at Taganskaya',
    3344: 'at Arbatskaya',
    3348: 'at Kitay-gorod / Lubyanka',
    39680: 'on Park of Culture',
    40523: 'at Akademicheskaya',
    40653: 'at Moscow-City',
    41379: 'on Khodynsky boulevard',
    43455: 'on Krasnoproletarskaya',
    44034: 'on Shabolovka',
    44038: 'on Krasina',
    44621: 'Tashkent',
    41298: 'on Timiryazeva',
    44617: 'on Nikitskaya',
    44623: 'on Ermakova',
  };

  const [metroStations, setMetroStations] = useState([
    { id: 1, name: 'Академическая', name_en: 'Akademicheskaya', link: 'restorany-u-metro-akademicheskaya' },
    { id: 2, name: 'Арбатская', name_en: 'Arbatskaya', link: 'restorany-u-metro-arbatskaya' },
    { id: 3, name: 'Багратионовская', name_en: 'Bagrationovskaya', link: 'restorany-u-metro-bagrationovskaya' },
    { id: 4, name: 'Белорусская', name_en: 'Belorusskaya', link: 'restorany-u-metro-belorusskaya' },
    { id: 5, name: 'Выставочная', name_en: 'Vystavochnaya', link: 'restorany-u-metro-vystavochnaya' },
    { id: 6, name: 'Деловой центр', name_en: 'Delovoy centr', link: 'restorany-u-metro-delovoj-centr' },
    { id: 7, name: 'Динамо', name_en: 'Dinamo', link: 'restorany-u-metro-dinamo' },
    { id: 8, name: 'Добрынинская', name_en: 'Dobryninskaya', link: 'restorany-u-metro-dobryninskaya' },
    { id: 9, name: 'Достоевская', name_en: 'Dostoevskaya', link: 'restorany-u-metro-dostoevskaya' },
    // { id: 10, name: 'Киевская', name_en: 'Kievskaya', link: 'restorany-u-metro-kievskaya' },
    { id: 11, name: 'Китай-Город', name_en: 'Kitay-Gorod', link: 'restorany-u-metro-kitaj-gorod' },
    // { id: 12, name: 'Краснопресненская', name_en: 'Krasnopresnenskaya', link: 'restorany-u-metro-krasnopresnenskaya' },
    { id: 13, name: 'Красные ворота', name_en: 'Krasnye vorota', link: 'restorany-u-metro-krasnye-vorota' },
    { id: 14, name: 'Кропоткинская', name_en: 'Kropotkinskaya', link: 'restorany-u-metro-kropotkinskaya' },
    { id: 15, name: 'Курская', name_en: 'Kurskaya', link: 'restorany-u-metro-kurskaya' },
    { id: 16, name: 'Лубянка', name_en: 'Lubyanka', link: 'restorany-u-metro-lubyanka' },
    { id: 17, name: 'Марксистская', name_en: 'Marksistskaya', link: 'restorany-u-metro-marksistskay' },
    { id: 18, name: 'Маяковская', name_en: 'Mayakovskaya', link: 'restorany-u-metro-mayakovskaya' },
    { id: 19, name: 'Международная', name_en: 'Mezhdunarodnaya', link: 'restorany-u-metro-mezhdunarodnaya' },
    { id: 20, name: 'Менделеевская', name_en: 'Mendeleevskaya', link: 'restorany-u-metro-mendeleevskaya' },
    { id: 21, name: 'Новослободская', name_en: 'Novoslobodskaya', link: 'restorany-u-metro-novoslobodskaya' },
    { id: 22, name: 'Октябрьская', name_en: 'Oktyabrskaya', link: 'restorany-u-metro-oktyabrskaya' },
    { id: 23, name: 'Парк Культуры', name_en: 'Park Kultury', link: 'restorany-u-metro-park-kultury' },
    { id: 24, name: 'Парк Победы', name_en: 'Park Pobedy', link: 'restorany-u-metro-park-pobedy' },
    { id: 25, name: 'Петровский парк', name_en: 'Petrovskij Park', link: 'restorany-u-metro-petrovskij-park' },
    { id: 26, name: 'Полянка', name_en: 'Polyanka', link: 'restorany-u-metro-polyanka' },
    { id: 27, name: 'Проспект Мира', name_en: 'Prospekt Mira', link: 'restorany-u-metro-prospekt-mira' },
    { id: 28, name: 'Профсоюзная', name_en: 'Profsoyuznaya', link: 'restorany-u-metro-profsoyuznaya' },
    { id: 29, name: 'Пушкинская', name_en: 'Pushkinskaya', link: 'restorany-u-metro-pushkinskaya' },
    { id: 30, name: 'Серпуховская', name_en: 'Serpuhovskaya', link: 'restorany-u-metro-serpuhovskaya' },
    // { id: 31, name: 'Смоленская', name_en: 'Smolenskaya', link: 'restorany-u-metro-smolenskaya' },
    { id: 32, name: 'Сретенский бульвар', name_en: 'Sretenskiy bulvar', link: 'restorany-u-metro-sretenskij-bulvar' },
    { id: 33, name: 'Сухаревская', name_en: 'Suharevskaya', link: 'restorany-u-metro-suharevskaya' },
    { id: 34, name: 'Таганская', name_en: 'Taganskaya', link: 'restorany-u-metro-taganskaya' },
    { id: 35, name: 'Тверская', name_en: 'Tverskaya', link: 'restorany-u-metro-tverskaya' },
    { id: 36, name: 'Тургеневская', name_en: 'Turgenevskaya', link: 'restorany-u-metro-turgenevskaya' },
    { id: 37, name: 'Университет', name_en: 'Universitet', link: 'restorany-u-metro-universitet' },
    { id: 38, name: 'Фили', name_en: 'Fili', link: 'restorany-u-metro-fili' },
    { id: 39, name: 'ЦСКА', name_en: 'CSKA', link: 'restorany-u-metro-cska' },
    { id: 40, name: 'Цветной бульвар', name_en: 'Cvetnoy Bulvar', link: 'restorany-u-metro-cvetnoj-bulvar' },
    { id: 41, name: 'Чеховская', name_en: 'Chekhovskaya', link: 'restorany-u-metro-chekhovskaya' },
    { id: 42, name: 'Чистые Пруды', name_en: 'Chistye Prudy', link: 'restorany-u-metro-chistye-prudy' },
    { id: 43, name: 'Чкаловская', name_en: 'Chkalovskaya', link: 'restorany-u-metro-chkalovskaya' },
    { id: 44, name: 'Шаболовская', name_en: 'Shabolovskaya', link: 'restorany-u-metro-shabolovskaya' },
  ]);

  const [selectedAddress, setSelectedAddress] = useState(11);
  // первый ресторан в текущем городе
  const [currentCityAddress, setCurrentCityAddress] = useState({});

  // нерабочее время ресторанов; если выходной - пустой массив
  const addressesTimeOff = [
    {
      id: '1208',
      cityId: 1,
      january_1_2024: [
        '00:00',
        '00:30',
        '01:00',
        '01:30',
        '02:00',
        '02:30',
        '03:00',
        '03:30',
        '04:00',
        '04:30',
        '05:00',
        '05:30',
        '06:00',
        '06:30',
        '07:00',
        '07:30',
        '08:00',
        '08:30',
        '09:00',
        '09:30',
        '10:00',
        '10:30',
        '11:00',
        '11:30',
        '12:00',
        '12:30',
        '13:00',
        '13:30',
        '14:00',
        '14:30',
        '15:00',
        '15:30',
      ],
    },
    {
      id: '40653',
      cityId: 1,
      january_1_2024: [
        '00:00',
        '00:30',
        '01:00',
        '01:30',
        '02:00',
        '02:30',
        '03:00',
        '03:30',
        '04:00',
        '04:30',
        '05:00',
        '05:30',
        '06:00',
        '06:30',
        '07:00',
        '07:30',
        '08:00',
        '08:30',
        '09:00',
        '09:30',
        '10:00',
        '10:30',
        '11:00',
        '11:30',
        '12:00',
        '12:30',
        '13:00',
        '13:30',
        '14:00',
        '14:30',
        '15:00',
        '15:30',
      ],
    },
    {
      id: '21',
      cityId: 1,
      january_1_2024: [
        '00:00',
        '00:30',
        '01:00',
        '01:30',
        '02:00',
        '02:30',
        '03:00',
        '03:30',
        '04:00',
        '04:30',
        '05:00',
        '05:30',
        '06:00',
        '06:30',
        '07:00',
        '07:30',
        '08:00',
        '08:30',
        '09:00',
        '09:30',
        '10:00',
        '10:30',
        '11:00',
        '11:30',
        '12:00',
        '12:30',
        '13:00',
        '13:30',
        '14:00',
        '14:30',
        '15:00',
        '15:30',
      ],
    },
    {
      id: '43455',
      cityId: 1,
      january_1_2024: [],
    },
  ];

  const standardTimeOff = [
    '00:00',
    '00:30',
    '01:00',
    '01:30',
    '02:00',
    '02:30',
    '03:00',
    '03:30',
    '04:00',
    '04:30',
    '05:00',
    '05:30',
    '06:00',
    '06:30',
    '07:00',
    '07:30',
    '08:00',
    '08:30',
    '09:00',
    '09:30',
    '10:00',
    '10:30',
    '11:00',
    '11:30',
  ];

  return (
    <GeneralContext.Provider
      value={{
        menuItems,
        setMenuItems,
        activeMenuItem,
        setActiveMenuItem,
        // items_geo, setItems_geo, activeGeo, setActiveGeo,
        cities,
        setCities,
        activeCity,
        setActiveCity,
        languages,
        setLanguages,
        activeLanguage,
        setActiveLanguage,
        windowWidth,
        setWindowWidth,
        detectWidth,
        windowHeight,
        setWindowHeight,
        subdomain,
        setSubdomain,
        path,
        setPath,
        selectedAddress,
        setSelectedAddress,
        currentCityAddress,
        setCurrentCityAddress,
        addressesTimeOff,
        standardTimeOff,
        addressesForTitle,
        addressesForTitle_en,
        metroStations,
        setMetroStations,
        subdomainIsActive,
        setSubdomainIsActive,
      }}
    >
      {children}
    </GeneralContext.Provider>
  );
}

export { GeneralContextProvider };
