import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { getBaseUrl } from '../functions/getBaseUrl';

const BASE_URL = getBaseUrl();

export const menuAPI = createApi({
  reducerPath: 'menuAPI',
  baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
  endpoints: build => ({
    fetchAllMenu: build.query({
      query: ({ address, category }) => ({
        url: '/api/menu',
        params: { address, category },
      }),
    }),
  }),
});

export const beerAPI = createApi({
  reducerPath: 'beerAPI',
  baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
  endpoints: build => ({
    fetchAllBeer: build.query({
      query: ({ address, format_beer, type_of_beer, strength, country, color, brewery }) => ({
        url: '/api/beer',
        params: { address, format_beer, type_of_beer, strength, country, color, brewery },
      }),
    }),
  }),
});

export const beerItemAPI = createApi({
  reducerPath: 'beerItemAPI',
  baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
  endpoints: build => ({
    fetchOneBeer: build.query({
      query: beerId => ({
        url: `/api/beer/${beerId}`,
      }),
    }),
  }),
});

export const beerPositionsAPI = createApi({
  reducerPath: 'beerPositionsAPI',
  baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
  endpoints: build => ({
    fetchBeerPositions: build.query({
      query: () => ({
        url: '/api/beers',
      }),
    }),
  }),
});

export const beerPositionOneAPI = createApi({
  reducerPath: 'beerPositionOneAPI',
  baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
  endpoints: build => ({
    fetchBeerPositionOne: build.query({
      query: beerSlug => ({
        url: `/api/beers?slug=${beerSlug}`,
      }),
    }),
  }),
});

export const beerSectionsAPI = createApi({
  reducerPath: 'beerSectionsAPI',
  baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
  endpoints: build => ({
    fetchBeerSections: build.query({
      query: () => ({
        url: '/api/beer/types',
      }),
    }),
  }),
});
