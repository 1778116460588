import { combineReducers, configureStore } from '@reduxjs/toolkit';
import {
  addressesAPI,
  settingsAPI,
  domainAPI,
  lambicClubLinkAPI,
  aboutInfoLinkAPI,
  articlesAPI,
  articleAPI,
  eventsAPI,
  eventAPI,
  sitemapAPI,
} from '../services/GeneralService';
import {
  menuAPI,
  beerAPI,
  beerItemAPI,
  beerPositionsAPI,
  beerPositionOneAPI,
  beerSectionsAPI,
} from '../services/MenuService';
import { mainTopSliderAPI, mainCategoriesSliderAPI, mainSeasonalSliderAPI } from '../services/MainService';

const rootReducer = combineReducers({
  [addressesAPI.reducerPath]: addressesAPI.reducer,
  [settingsAPI.reducerPath]: settingsAPI.reducer,
  [domainAPI.reducerPath]: domainAPI.reducer,
  [lambicClubLinkAPI.reducerPath]: lambicClubLinkAPI.reducer,
  [aboutInfoLinkAPI.reducerPath]: aboutInfoLinkAPI.reducer,
  [articlesAPI.reducerPath]: articlesAPI.reducer,
  [articleAPI.reducerPath]: articleAPI.reducer,
  [eventsAPI.reducerPath]: eventsAPI.reducer,
  [eventAPI.reducerPath]: eventAPI.reducer,
  [sitemapAPI.reducerPath]: sitemapAPI.reducer,
  [menuAPI.reducerPath]: menuAPI.reducer,
  [beerAPI.reducerPath]: beerAPI.reducer,
  [beerItemAPI.reducerPath]: beerItemAPI.reducer,
  [beerPositionsAPI.reducerPath]: beerPositionsAPI.reducer,
  [beerPositionOneAPI.reducerPath]: beerPositionOneAPI.reducer,
  [beerSectionsAPI.reducerPath]: beerSectionsAPI.reducer,
  [mainTopSliderAPI.reducerPath]: mainTopSliderAPI.reducer,
  [mainCategoriesSliderAPI.reducerPath]: mainCategoriesSliderAPI.reducer,
  [mainSeasonalSliderAPI.reducerPath]: mainSeasonalSliderAPI.reducer,
});

export const setupStore = () =>
  configureStore({
    reducer: rootReducer,
    middleware: getDefaultMiddleware =>
      getDefaultMiddleware().concat([
        addressesAPI.middleware,
        settingsAPI.middleware,
        domainAPI.middleware,
        lambicClubLinkAPI.middleware,
        aboutInfoLinkAPI.middleware,
        articlesAPI.middleware,
        articleAPI.middleware,
        eventsAPI.middleware,
        eventAPI.middleware,
        sitemapAPI.middleware,
        menuAPI.middleware,
        beerAPI.middleware,
        beerItemAPI.middleware,
        beerPositionsAPI.middleware,
        beerPositionOneAPI.middleware,
        beerSectionsAPI.middleware,
        mainTopSliderAPI.middleware,
        mainCategoriesSliderAPI.middleware,
        mainSeasonalSliderAPI.middleware,
      ]),
  });
