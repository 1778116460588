import { createContext, useState } from 'react';

export const FeedbackContext = createContext();

function FeedbackContextProvider({ children }) {
  const [typeReview, setTypeReview] = useState([
    {
      value: 'question',
      label: 'Вопрос',
    },
    {
      value: 'dissatisfied',
      label: 'Разочарованный гость',
    },
    {
      value: 'pleased',
      label: 'Довольный гость',
    },
    {
      value: 'other',
      label: 'Другое',
    },
  ]);
  const [typeReviewEn, setTypeReviewEn] = useState([
    {
      value: 'question',
      label: 'Question',
    },
    {
      value: 'dissatisfied',
      label: 'Disappointed guest/Complain',
    },
    {
      value: 'pleased',
      label: 'Satisfied guest/Gratitude',
    },
    {
      value: 'other',
      label: 'Other',
    },
  ]);

  const [motiveFeedback, setMotiveFeedback] = useState([
    {
      value: 'dish',
      label: 'Еда / Напитки',
    },
    {
      value: 'overallImpression',
      label: 'Общее впечатление',
    },
    {
      value: 'service',
      label: 'Обслуживание',
    },
    {
      value: 'pricingPolicy',
      label: 'Ценовая политика',
    },
    {
      value: 'incidents',
      label: 'Происшествия',
    },
    {
      value: 'otherMotive',
      label: 'Другое',
    },
  ]);
  const [motiveFeedbackEn, setMotiveFeedbackEn] = useState([
    {
      value: 'dish',
      label: 'Food/Drinks',
    },
    {
      value: 'overallImpression',
      label: 'General impression',
    },
    {
      value: 'service',
      label: 'Service',
    },
    {
      value: 'pricingPolicy',
      label: 'Price policy',
    },
    {
      value: 'incidents',
      label: 'Accidents',
    },
    {
      value: 'otherMotive',
      label: 'Other',
    },
  ]);

  return (
    <FeedbackContext.Provider
      value={{
        typeReview,
        setTypeReview,
        typeReviewEn,
        setTypeReviewEn,
        motiveFeedback,
        setMotiveFeedback,
        motiveFeedbackEn,
        setMotiveFeedbackEn,
        // address, setAddress,
      }}
    >
      {children}
    </FeedbackContext.Provider>
  );
}

export { FeedbackContextProvider };
