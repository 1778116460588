import { createContext, useState } from 'react';

export const MenuContext = createContext();

function MenuContextProvider({ children }) {
  const [selectedMenuSection, setSelectedMenuSection] = useState(0);

  const [menuSectionTypes] = useState([
    { id: 1, type: 'food', name: 'Меню', name_en: 'Menu' },
    { id: 2, type: 'drink', name: 'Напитки', name_en: 'Drinks' },
  ]);

  const [selectedMenuSectionType, setSelectedMenuSectionType] = useState(1);

  return (
    <MenuContext.Provider
      value={{
        selectedMenuSection,
        setSelectedMenuSection,
        menuSectionTypes,
        selectedMenuSectionType,
        setSelectedMenuSectionType,
      }}
    >
      {children}
    </MenuContext.Provider>
  );
}

export { MenuContextProvider };
