import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { GeneralContextProvider } from './context/generalContext';
import { setupStore } from './store/store';
import './styles/normalize.css';
import { Provider } from 'react-redux';
import { HelmetProvider } from 'react-helmet-async';

const store = setupStore();

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <GeneralContextProvider>
    <Provider store={store}>
      <HelmetProvider>
        <App />
      </HelmetProvider>
    </Provider>
  </GeneralContextProvider>,
);
