import React, { Suspense, lazy, useContext, useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { Helmet } from 'react-helmet-async';
import './styles/App.scss';
import './styles/skeleton.scss';
import { AboutContextProvider } from './context/aboutContext';
import { MenuContextProvider } from './context/menuContext';
import { GeneralContext } from './context/generalContext';
import { FeedbackContextProvider } from './context/feedbackContext';
import { BeerContextProvider } from './context/beerContext';
import { SiteMapContextProvider } from './context/siteMapContext';
import CormorantGaramondBold from './fonts/cormorant_garamond/CormorantGaramond-Bold.woff2';
import OpenSans from './fonts/open-sans/OpenSans-Regular.woff2';
import SkeletonAddressesPage from './Components/Skeleton/SkeletonAddresses/SkeletonAddressesPage';
import SkeletonBeer from './Components/Skeleton/SkeletonBeer/SkeletonBeer';
import { checkIsAltai } from './functions/checkCity/checkCity';
import RestaurantModal from './Components/RestaurantModal/RestaurantModal';

const Main = lazy(() => import('./pages/Main/Main'));
const Footer = lazy(() => import('./Components/Footer/Footer'));
const Header = lazy(() => import('./Components/Header/Header'));
const CookieConsentBar = lazy(() => import('./Components/CookieConsentBar/CookieConsentBar'));
const AgeConfirmModal = lazy(() => import('./Components/AgeConfirmModal/AgeConfirmModal'));
const About = lazy(() => import('./pages/About/AboutGeneral/AboutGeneral'));
const MenuGeneral = lazy(() => import('./pages/Menu/MenuGeneral/MenuGeneral'));
const EventsGeneral = lazy(() => import('./pages/Events/EventsGeneral/EventsGeneral'));
const Policy = lazy(() => import('./pages/Policy/Policy'));
const Reserve = lazy(() => import('./pages/Reserve/Reserve'));
const Addresses = lazy(() => import('./pages/Addresses/Addresses'));
const Feedback = lazy(() => import('./pages/Feedback/Feedback'));
const BeerNavigation = lazy(() => import('./pages/Beer/BeerNavigation'));
const SiteMap = lazy(() => import('./pages/SiteMap/SiteMap'));
const Franchise = lazy(() => import('./pages/Franchise/Franchise'));
const NotFound = lazy(() => import('./pages/NotFound/NotFound'));
const EventPage = lazy(() => import('./pages/Events/EventPage/EventPage'));
const ArticlePage = lazy(() => import('./pages/Articles/ArticlePage/ArticlePage'));
const ArticlesGeneral = lazy(() => import('./pages/Articles/ArticlesGeneral/ArticlesGeneral'));
const LambicClub = lazy(() => import('./pages/Events/LambicClub/LambicClub'));
const ClubApp = lazy(() => import('./pages/ClubApp/ClubApp'));
const ClosedRestaurantModal = lazy(() => import('./Components/ClosedRestaurantModal/ClosedRestaurantModal'));
const ClosedReserveModal = lazy(() => import('./Components/ClosedReserveModal/ClosedReserveModal'));
const Delivery = lazy(() => import('./pages/Delivery/Delivery'));

const menuComponent = (
  <MenuContextProvider>
    <MenuGeneral />
  </MenuContextProvider>
);

const aboutComponent = (
  <AboutContextProvider>
    <About />
  </AboutContextProvider>
);

const feedbackComponent = (
  <FeedbackContextProvider>
    <Feedback />
  </FeedbackContextProvider>
);

const beerComponent = (
  <BeerContextProvider>
    <BeerNavigation />
  </BeerContextProvider>
);

const sitemapComponent = (
  <SiteMapContextProvider>
    <SiteMap />
  </SiteMapContextProvider>
);

const routesData = {
  '/': <Main />,
  '/en': <Main />,
  '/menu/*': menuComponent,
  '/menu/:section': menuComponent,
  '/menu/:section/:restaurant': menuComponent,
  '/en/menu/*': menuComponent,
  '/en/menu/:section': menuComponent,
  '/en/menu/:section/:restaurant': menuComponent,
  '/about/*': aboutComponent,
  '/en/about/*': aboutComponent,
  '/articles/*': <ArticlesGeneral />,
  '/en/articles/*': <ArticlesGeneral />,
  '/articles/:articleName': <ArticlePage />,
  '/en/articles/:articleName': <ArticlePage />,
  '/events/*': <EventsGeneral />,
  '/en/events/*': <EventsGeneral />,
  '/events/:eventName': <EventPage />,
  '/en/events/:eventName': <EventPage />,
  '/events/lambicclub': <LambicClub />,
  '/en/events/lambicclub': <LambicClub />,
  '/order': <Reserve />,
  '/en/order': <Reserve />,
  '/privacy-policy': <Policy />,
  '/en/privacy-policy': <Policy />,
  '/address/*': <Addresses />,
  '/en/address/*': <Addresses />,
  '/franchise': <Franchise />,
  '/en/franchise': <Franchise />,
  '/feedback': feedbackComponent,
  '/en/feedback': feedbackComponent,
  '/beer/*': beerComponent,
  '/en/beer/*': beerComponent,
  '/sitemap': sitemapComponent,
  '/en/sitemap': sitemapComponent,
  '/club-app': <ClubApp />,
  '/en/club-app': <ClubApp />,
  '/delivery': <Delivery />,
  '/en/delivery': <Delivery />,
  '*': <NotFound />,
};

const routesDataSuspense = {
  '/address/*': <SkeletonAddressesPage />,
  '/en/address/*': <SkeletonAddressesPage />,
  '/beer/*': (
    <div className='wrapper'>
      <SkeletonBeer />
    </div>
  ),
  '/en/beer/*': (
    <div className='wrapper'>
      <SkeletonBeer />
    </div>
  ),
};

function App() {
  const { detectWidth, subdomain } = useContext(GeneralContext);

  const cookies = useCookies();

  useEffect(() => {
    window.addEventListener('resize', detectWidth);
    return () => {
      window.removeEventListener('resize', detectWidth);
    };
  });

  return (
    <BrowserRouter>
      <Helmet>
        <link rel='preload' href={CormorantGaramondBold} as='Cormorant Garamond Bold' type='font/woff2' />
        <link rel='preload' href={OpenSans} as='Open Sans Regular' type='font/woff2' />
      </Helmet>
      <Header />
      <Routes>
        {checkIsAltai() ? (
          <Route path='*' element={<NotFound />} />
        ) : (
          <>
            {Object.entries(routesData).map(([path, component]) => (
              <Route
                key={path}
                path={path}
                element={
                  <Suspense fallback={routesDataSuspense[path] || <section style={{ height: '80vh' }} />}>
                    {component}
                  </Suspense>
                }
              />
            ))}
          </>
        )}
      </Routes>
      <Footer />
      {!cookies[0].ageConfirmed && <AgeConfirmModal />}
      {subdomain === '16' && !cookies[0].KutuzovskyClosed && (
        <Suspense fallback={<section style={{ height: '80vh' }} />}>
          <ClosedRestaurantModal />
        </Suspense>
      )}
      {subdomain === '14' && !cookies[0].SuharevskayaClosed && (
        <Suspense fallback={<section style={{ height: '80vh' }} />}>
          <ClosedRestaurantModal />
        </Suspense>
      )}
      {subdomain !== '16' && subdomain !== '14' && !cookies[0].reserveClosed && (
        <Suspense fallback={<section style={{ height: '80vh' }} />}>
          <ClosedReserveModal />
        </Suspense>
      )}
      {!cookies[0].restaurantModal && (
        <Suspense fallback={<section style={{ height: '80vh' }} />}>
          <RestaurantModal />
        </Suspense>
      )}
      <CookieConsentBar />
    </BrowserRouter>
  );
}

export default App;
