import React from 'react';
import SkeletonAddresses from './SkeletonAddresses';
import classes from './SkeletonAddresses.module.scss';
import Skeleton from '../Skeleton';

export default function SkeletonAddressesPage() {
  return (
    <>
      <div className='wrapper'>
        <div className={classes.navpanel}>
          <Skeleton borderRadius='5px' width={160} height={15} isLight={false} />
        </div>
        <div className={classes.header}>
          <Skeleton borderRadius='5px' width={130} height={25} isLight={false} />
        </div>
      </div>
      <SkeletonAddresses />
    </>
  );
}
