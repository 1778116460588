import { createContext, useState } from 'react';

export const SiteMapContext = createContext();

function SiteMapContextProvider({ children }) {
  const [drinks, setDrinks] = useState([
    {
      category: {
        id: 1,
        value: 'Пиво',
        subtitle: 'Разливное пиво',
      },
      items: [
        {
          id: 1,
          value: 'St. Feuillien Cuvee De Noel',
        },
        {
          id: 2,
          value: 'Averbode Blonde',
        },
        {
          id: 3,
          value: 'Bourgogne Des Flandres Grand Cru',
        },
        {
          id: 4,
          value: 'Collin’s British Stout',
        },
        {
          id: 5,
          value: 'Campus Premium',
        },
        {
          id: 6,
          value: 'SweetWater Hazy IPA',
        },
        {
          id: 7,
          value: 'Harviestoun Old Engine Oil',
        },
        {
          id: 8,
          value: 'Backswood Apple Cider',
        },
        {
          id: 9,
          value: 'Bayreuther Hell',
        },
        {
          id: 10,
          value: "Carlow O'Hara's Irish Stout",
        },
        {
          id: 11,
          value: 'Bourgogne des Flandres',
        },
        {
          id: 12,
          value: 'St. Feuillien Grisette Blanche Bio',
        },
        {
          id: 13,
          value: 'Belle-Vue Kriek',
        },
        {
          id: 14,
          value: "Marston's Oyster Stout",
        },
        {
          id: 15,
          value: 'Delirium Red',
        },
        {
          id: 16,
          value: 'Barbe Ruby',
        },
        {
          id: 17,
          value: 'Bourgogne Des Flandres Blonde',
        },
        {
          id: 18,
          value: 'Pater Linus Blond',
        },
        {
          id: 19,
          value: 'Pater Linus Tripel',
        },
        {
          id: 20,
          value: 'Tripel Karmeliet',
        },
        {
          id: 21,
          value: 'Bullevie Queency',
        },
        {
          id: 22,
          value: 'Triporteur Full Moon 12',
        },
        {
          id: 23,
          value: 'Zoller-Hof Spezial Export',
        },
        {
          id: 24,
          value: 'Pauwel Kwak',
        },
        {
          id: 25,
          value: 'Rogue Dead Guy',
        },
        {
          id: 26,
          value: 'Rogue Hazelnut Brown Nectar',
        },
        {
          id: 27,
          value: 'Black Sheep IPA',
        },
        {
          id: 28,
          value: 'Poppels DIPA',
        },
        {
          id: 29,
          value: 'Bornem Blond',
        },
        {
          id: 30,
          value: "Monk's Cafe",
        },
        {
          id: 31,
          value: 'Weihenstephaner Original Helles',
        },
        {
          id: 32,
          value: 'Camba Hell',
        },
        {
          id: 33,
          value: 'Satyr',
        },
        {
          id: 34,
          value: 'Panzer Black Cat',
        },
        {
          id: 35,
          value: 'Spaten Munchen',
        },
        {
          id: 36,
          value: 'Guinness',
        },
        {
          id: 37,
          value: 'Cantillon Gueuze 100% Lambic Bio',
        },
        {
          id: 38,
          value: 'Schofferhofer Grapefruit',
        },
        {
          id: 39,
          value: 'Mort Subite Kriek Lambic',
        },
        {
          id: 40,
          value: 'Grimbergen Blоnde',
        },
        {
          id: 41,
          value: 'Mikkeller Blow Out',
        },
        {
          id: 42,
          value: 'Honey Porter',
        },
        {
          id: 43,
          value: 'Cornelissen',
        },
        {
          id: 44,
          value: 'Skinny Lager',
        },
        {
          id: 45,
          value: 'Petrus Blonde',
        },
        {
          id: 46,
          value: 'Petrus Aged Red',
        },
        {
          id: 47,
          value: 'Petrus Bordeaux',
        },
        {
          id: 48,
          value: 'Adriaen Brouwer',
        },
        {
          id: 49,
          value: 'Mort Subite Witte Lambic',
        },
        {
          id: 50,
          value: 'Blanche de Bruxelles',
        },
        {
          id: 51,
          value: 'Schneider Weisse Tap 1',
        },
        {
          id: 52,
          value: 'Schneider Weisse Tap 7 Mein Original',
        },
        {
          id: 53,
          value: 'Schneider Weisse TAP5 Meine Hopfen-Weisse',
        },
        {
          id: 54,
          value: 'Blue Moon',
        },
        {
          id: 55,
          value: 'Lindemans Kriek',
        },
        {
          id: 56,
          value: 'Kona Hanalei Island IPA',
        },
        {
          id: 57,
          value: 'St. Louis Premium Kriek',
        },
        {
          id: 58,
          value: 'Jopen Koyt Gruitbier',
        },
        {
          id: 59,
          value: 'Fournier Artisanal Brut',
        },
        {
          id: 60,
          value: 'Ivory Oatmeal Stout',
        },
        {
          id: 61,
          value: 'Schneider Weisse Tap 6',
        },
        {
          id: 62,
          value: "Belhaven McCallum's Stout",
        },
        {
          id: 63,
          value: 'Kriek Max',
        },
        {
          id: 64,
          value: 'Grimbergen Double-Ambree',
        },
        {
          id: 65,
          value: 'Woodbridge Stout',
        },
        {
          id: 66,
          value: 'St. Bernardus Abt 12',
        },
        {
          id: 67,
          value: 'Sint Gummarus Tripel',
        },
        {
          id: 68,
          value: 'Grimbergen Rouge',
        },
        {
          id: 69,
          value: 'Rebel Apple Medium Dry',
        },
        {
          id: 70,
          value: 'Piraat',
        },
        {
          id: 71,
          value: 'Leffe Blonde',
        },
        {
          id: 72,
          value: 'Mikkeller Stick A Finger In the Soil',
        },
        {
          id: 73,
          value: 'Lindemans Faro',
        },
        {
          id: 74,
          value: 'Lindemans Cassis',
        },
        {
          id: 75,
          value: 'Troubadour Magma',
        },
        {
          id: 76,
          value: 'Kasteel Barista Chocolate Quad',
        },
        {
          id: 77,
          value: 'Super 8 Blanche',
        },
        {
          id: 78,
          value: 'Omnipollo Mazarin',
        },
        {
          id: 79,
          value: 'Straffe Hendrik Quadrupel',
        },
        {
          id: 80,
          value: "Carlow O'Hara's Irish Red",
        },
        {
          id: 81,
          value: 'Poppels New England IPA',
        },
        {
          id: 82,
          value: 'BrewDog Lost Lager',
        },
        {
          id: 83,
          value: 'Lindemans Framboise (Линдеманс Малина)',
        },
        {
          id: 84,
          value: 'Blanche De Bruges',
        },
        {
          id: 85,
          value: 'Omnipollo Leon',
        },
        {
          id: 86,
          value: 'BrewDog Punk IPA',
        },
        {
          id: 87,
          value: 'BrewDog Pale Ale',
        },
        {
          id: 88,
          value: 'La Trappe Quadrupel',
        },
        {
          id: 89,
          value: 'Elvis Juice',
        },
        {
          id: 90,
          value: 'Kona Longboard Island Lager',
        },
        {
          id: 91,
          value: 'Riegele Herren Pils',
        },
        {
          id: 92,
          value: "La Trappe Isid'or",
        },
        {
          id: 93,
          value: 'Leffe Brune',
        },
        {
          id: 94,
          value: 'Riegele Commerzienrat Privat',
        },
        {
          id: 95,
          value: 'Young’s Double Chocolate Stout',
        },
        {
          id: 96,
          value: 'Grimbergen Blanche',
        },
        {
          id: 97,
          value: 'Brooklyn Lager',
        },
        {
          id: 98,
          value: 'Bacchus Frambozenbier',
        },
        {
          id: 99,
          value: 'Brooklyn East IPA',
        },
        {
          id: 100,
          value: 'Benediktiner Original Hell',
        },
        {
          id: 101,
          value: 'Lockdown IPA',
        },
        {
          id: 102,
          value: 'Delirium Tremens',
        },
        {
          id: 103,
          value: 'Harviestoun Schiehallion',
        },
        {
          id: 104,
          value: 'Arcobrau Urfass',
        },
      ],
    },
    {
      category: {
        id: 2,
        value: 'Бельгийское траппистское пиво',
      },
      items: [
        {
          id: 105,
          value: 'Achel 8 Bruin',
        },
        {
          id: 106,
          value: 'Achel 8 Blonde',
        },
        {
          id: 1060,
          value: 'Chimay Triple',
        },
        {
          id: 107,
          value: 'Chimay Blue',
        },
        {
          id: 108,
          value: 'Chimay Red',
        },
        {
          id: 109,
          value: 'Trappistes Rochefort Triple Extra',
        },
        {
          id: 110,
          value: 'Trappistes Rochefort 6',
        },
        {
          id: 111,
          value: 'Trappistes Rochefort 8',
        },
        {
          id: 112,
          value: 'Trappistes Rochefort 10',
        },
        {
          id: 113,
          value: 'Westmalle Trappist Tripel',
        },
        {
          id: 114,
          value: 'Westmalle Trappist Extra',
        },
        {
          id: 115,
          value: 'Westmalle Trappist Dubbel',
        },
        {
          id: 116,
          value: 'Orval',
        },
      ],
    },
    {
      category: {
        id: 3,
        value: 'Сидр',
      },
      items: [
        {
          id: 117,
          value: 'Maeloc Cidra Seca',
        },
        {
          id: 118,
          value: 'Maeloc Cidra Ecologica',
        },
        {
          id: 119,
          value: 'Aspall Draught',
        },
        {
          id: 120,
          value: 'Cidre de Bretagne Brut Bio',
        },
        {
          id: 121,
          value: "Samuel Smith's Organic Perry",
        },
        {
          id: 122,
          value: "Samuel Smith's Organic Cider",
        },
        {
          id: 123,
          value: 'The Good Cider Wild Berry',
        },
        {
          id: 124,
          value: 'The Good Cider Peach',
        },
        {
          id: 125,
          value: 'Chevre de Valognes Cidre Artisanal Rose',
        },
        {
          id: 126,
          value: 'Chevre de Valognes Cidre Artisanal Brut',
        },
        {
          id: 127,
          value: 'Chevre de Valognes Artisanal Poire',
        },
        {
          id: 128,
          value: 'Chevre de Valognes Cidre Artisanal Organic Brut',
        },
      ],
    },
    {
      category: {
        id: 4,
        value: 'Траппистское пиво из других стран',
      },
      items: [
        {
          id: 129,
          value: 'Tynt Meadow',
        },
        {
          id: 130,
          value: 'Benno Trappist',
        },
        {
          id: 131,
          value: 'La Trappe Blond',
        },
        {
          id: 132,
          value: 'La Trappe Tripel',
        },
        {
          id: 133,
          value: 'La Trappe Witte',
        },
        {
          id: 134,
          value: "La Trappe Isi'dor",
        },
        {
          id: 135,
          value: 'La Trappe Dubbel',
        },
        {
          id: 136,
          value: 'La Trappe Quadrupel',
        },
        {
          id: 137,
          value: 'La Trappe Quadrupel Oak Aged',
        },
        {
          id: 138,
          value: 'Spencer IPA',
        },
        {
          id: 139,
          value: 'Spenсer Imperial Stout',
        },
        {
          id: 140,
          value: 'Tre Fontane Tripel',
        },
        {
          id: 141,
          value: 'Zundert Trappist 8',
        },
        {
          id: 142,
          value: 'Mont des Cats',
        },
        {
          id: 143,
          value: 'Zundert Trappist 10',
        },
      ],
    },
  ]);

  return (
    <SiteMapContext.Provider
      value={{
        drinks,
        setDrinks,
      }}
    >
      {children}
    </SiteMapContext.Provider>
  );
}

export { SiteMapContextProvider };
