// проверка находимся ли мы на сайте Ташкента (lambic.uz / tashkent.lambic.keep-calm.ru / tashkent.stage.lambic.ru)
export const checkIsTashkent = () => {
  const countryCode = window.location.hostname.split('.')[window.location.hostname.split('.').length - 1];
  const subdomain = window.location.hostname.split('.')[0];
  const isTashkent = countryCode === 'uz' || subdomain === 'test' || subdomain === 'tashkent';
  return isTashkent;
};

export const checkIsAltai = () => {
  const subdomain = window.location.hostname.split('.')[0];
  const isAltai = subdomain === 'alt';
  return isAltai;
};

export const checkIsMainDomain = () => {
  const { hostname } = window.location;
  const isMainDomain =
    hostname === 'lambic.ru' ||
    hostname === 'stage.lambic.ru' ||
    hostname === 'lambic.keep-calm.ru' ||
    hostname === 'localhost';

  return isMainDomain;
};
